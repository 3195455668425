@import "../../styles/mixins";

@mixin theme() {
  width: 100%;
  display: flex;
  padding: 10px;
  background: none;
  border: none;
}

// Fill Background
.bgInfo,
div.bgInfo {
  background: #f2f4f8;
}
.bgSuccess,
div.bgSuccess {
  background: #00968814;
}
.bgWarning,
div.bgWarning {
  background: #fb8c0014;
}
.bgError,
div.bgError {
  background: #ff000014;
}
.bgDark {
  color: #616161;
  background: black;
}
.bgDefault {
  background: #b6b6b633;
}
.bgRed {
  background: #ffeaea;
}

.textInfo {
  background: #EDE7F4;
  padding: 12px 16px;
  border-radius: 8px;
}

.textError {
  background: #FFEEE6;
  padding: 12px 16px;
  border-radius: 8px;
}

// Fill Background Table Row
table tr {
  &.bgInfo {
    background: material-color("blue", "50");
    td {
      color: material-color("blue", "500");
    }
  }
  &.bgSuccess {
    background: material-color("green", "50");
    td {
      color: material-color("green", "500");
    }
  }
  &.bgWarning {
    background: material-color("orange", "50");
    td {
      color: material-color("orange", "500");
    }
  }
  &.bgError {
    background: material-color("red", "50");
    td {
      color: material-color("red", "500");
    }
  }
  &.bgDefault {
    background: #fff;
    td {
      color: #212121;
    }
  }
}

// Icon Background
.messageInfo {
  @include theme();
  .icon {
    background: #f2f4f8;
    color: #1976d2;
  }
}
.messageSuccess {
  @include theme();
  .icon {
    background: #00968814;
    color: #009688;
  }
}
.messageWarning {
  @include theme();
  .icon {
    background: #fb8c0014;
    color: #fb8c00;
  }
}
.messageError {
  @include theme();
  .icon {
    background: #ff000014;
    color: #ff0000;
  }
}
.messageDefault {
  @include theme();
  .icon {
    background: #b6b6b633;
    color: #aeaeae;
  }
}
