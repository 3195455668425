.confirmDialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000000;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    padding: 20px;
    width: 400px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);
    box-shadow: 0 0 8px rgba(0,0,0,0.35);
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1000000000000111;
    .content {
        .title {
            font-size: 20px;
            color: #333;
        }
        .text {
            font-size: 16px;
            font-weight: 400;
            color: #777
        }
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 16px;
      button {
        width: 100px;
        padding: 8px 10px;
        background-color: transparent;
        color: #333;
        font-size: 16px;
        font-weight: 500;
        outline: none;
        border-radius: 5px;
        box-sizing: border-box;
        cursor: pointer;
        &.confirmButton {
          background-color: #673AB7;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .box {
      width: 80%;
      button {
        font-size: 14px;
      }
    }
  }
}




