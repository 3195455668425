.dotsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  border-radius: 100%;
  aspect-ratio: 1;
  animation: dots 2s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
  background-color: #9e77f6;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
  background-color: #77d8f6b7;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
  background-color: #9e77f6;
}

.dot:nth-child(4) {
  animation-delay: 0.8s;
  background-color: #77d8f6b7;
}

@keyframes dots {
  50% {
    opacity: 0.5;
    transform: scale(0.7) translateY(5px);
  }
}
