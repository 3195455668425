.recharts-text {
  font-size: 11px
}
.dark-mode .recharts-text{
  fill: #ededed;
}
.light-mode .recharts-text{
  fill: #838383;
}
.recharts-cartesian-grid-vertical line {
  stroke: #a3a3a3;
}

.recharts-legend-item-text {
  font-size: 12px
}

.recharts-wrapper {
  overflow: hidden
}

.dark-mode .recharts-default-tooltip {
  background-color: rgb(53, 53, 53) !important;
  border: 1px solid rgb(0, 0, 0) !important;
}