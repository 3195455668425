html {
  background-color: $viewport-background-color;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  scroll-behavior: smooth;
}

*:focus {
  outline: none
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(123.35deg, #77d8f673 -12.09%, #9d77f65f -12.07%, #77d8f673 194.21%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9d77f65f; 
}